const tools = require("../tools.cjs");
export default class AnimatedHeroWC extends HTMLElement {
    #animatedWords = []
    #finished = false;
    #started = false;
    constructor() {
        super();
        this.prepare();
    }

    prepare() {
        this.#animatedWords;
        for (let animatedWord of this.children) {
            if (animatedWord.tagName.toLowerCase() === 'animated-word') {
                this.#animatedWords.push(animatedWord);
            }
        }
    }

    reset() {
        const promises = []
        this.#animatedWords.forEach(w => promises.push(w.reset()));
        return Promise.all(promises);
    }

    play(delay=0) {
        if(!this.#started) {
            setTimeout(() => {
                this.#started = true;
                if(this.#finished) {
                    this.#finished = false;
                    this.reset().then(() => this.anim());
                } else {
                    this.anim();
                }
            }, delay)
        }
    }

    anim() {
        Promise.all(this.run(300)).then(() => this.clean(100))
            .then(() => this.anim())
            .catch(() => {
                this.#finished = true;
                this.#started = false;
            })

    }

    clean(delay = 300) {
        const cleanPromises = [];
        let index = 0;
        this.#animatedWords.forEach(animWord => {
            if (animWord.hasNext()) {
                const localDelay = animWord.idling() ? 0 : index++ * delay;

                if(localDelay === 0)
                    cleanPromises.push(animWord.prepareForNext())
                else
                    cleanPromises.push(tools.delayedPromise(localDelay).then(() => animWord.prepareForNext()));
            }
        })

        if(cleanPromises.length > 0) {
            return Promise.all(cleanPromises)
        } else {
            return Promise.reject();
        }
    }

    run(delay = 300) {
        const promises = [];
        let index = 0;
        this.#animatedWords.forEach(animWord => {
            if (animWord.hasNext()) {
                const localDelay = animWord.idling() ? 0 : index++ * delay;
                if(localDelay === 0) promises.push(animWord.play());
                else
                    promises.push(
                        tools.delayedPromise(localDelay).then(() => animWord.play())
                    );
            }
        })

        return promises;
    }
}
