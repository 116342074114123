import Anim from "./Anim.js";

export default class Idle extends Anim {
    name = "idle";

    animate() {return Promise.resolve();}

    show(word) {
        return Promise.resolve();
    }

    hide() {
        return Promise.resolve();
    }

    clean() {
        return Promise.resolve();
    }
}