class Divider {
    constructor(elt, arranger) {
        this.elt = elt;
        this.arranger = arranger;
        this.dividerElt;
        this.yPos;
        this.visible = false;
    }

    claimVisibility() {
        this.arranger.provideVisibility(this);
    }

    setDividerElt(htmlElt) {
        this.dividerElt = htmlElt;
    }

    hide() {
        this.visible = false;
        this.dividerElt.style.opacity = 0;
    }

    show() {
        this.visible = true;
        this.dividerElt.style.opacity = 1;
    }

    isVisible() {return this.visible;}

    getDividerElt() {return this.dividerElt;}
    getElt() {return this.elt;}
    equals(another) {return this.yPos === another.yPos;}
}

export default Divider
