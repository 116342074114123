import Lazy from "../lazy/Lazy.js";
import Divider from "./Divider.js";

const paraloop = (divider = new ImageDivider(), wHeight = window.innerHeight) => {
    const step = timestamp => {
        if(divider.isVisible()) {
            let eltY = divider.getElt().getBoundingClientRect().top;
            let dividerY = divider.getProgRate() * eltY;
            let isSafari = navigator.vendor.match(/apple/i) &&
                !navigator.userAgent.match(/crios/i) &&
                !navigator.userAgent.match(/fxios/i) &&
                !navigator.userAgent.match(/Opera|OPT\//);

            if (isSafari) {
                let dividerY = 0.18 * eltY;
                divider.getDividerElt().style.transform = 'translateY(' + dividerY + 'px)';
            } else {
                divider.getDividerElt().style.transform = 'translateY(' + dividerY + 'px)';
            }
            requestAnimationFrame(step);
        }
    }
    requestAnimationFrame(step);
}

class ImageDivider extends Divider {
    constructor(elt, arranger) {
        super(elt, arranger);
        this.yPos;
        this.urls = this.elt.dataset.imgs.split(',');
        this.imgsPos = this.elt.dataset.imgsPos.split(',')
        this.index = 0;
        this.dividerEltRelSize = 1;
        this.dividerEltProgRate = 0.25;
        this.imgElement = null
    }

    init() {
        this.yPos = this.elt.offsetTop;
        if(this.urls) {
            this.index = Math.floor(Math.random() * this.urls.length);
            this.imgElement =  document.createElement('img');
            this.imgElement.style.opacity = 0;
            this.imgElement.style.visibility = 'hidden';
            this.imgElement.style.height = (window.innerHeight * this.getRelSize()) + 'px';
            this.imgElement.style.objectPosition = this.imgsPos[this.index];

            this.imgElement.src = this.getNextImgUrl();

            this.arranger.addDivider(this.imgElement, this);
            this.imgElement.onload = () => {
                this.imgElement.style.opacity = 1;
                new Lazy({
                    targets: this.elt,
                    persist: true,
                    rootMargin: {top: 500, right: 0, left: 0, bottom: 500},
                    loadFn: elt => {
                        this.claimVisibility()
                    }
                })
            }
            setTimeout(() => {
                this.imgElement.style.visibility = 'visible'
            },300)
        }
    }

    recalculateHeight() {
        this.imgElement.style.height = (window.innerHeight * this.getRelSize()) + 'px';
    }

    getNextImgUrl() {
        const url = this.urls[this.index++];
        this.index = this.index % this.urls.length;
        return url;
    }

    hide() {
        super.hide();
    }

    show() {
        super.show();
        paraloop(this, window.innerHeight);
    }

    getRelSize() {return this.dividerEltRelSize;}
    getProgRate() {return this.dividerEltProgRate;}
}

export default ImageDivider;
