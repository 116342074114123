import AppearAnim from "./AppearAnim.js";
import AnimatedWordWC from "./AnimatedWordWC.js";

export default class LetterMove extends AppearAnim {
    name = 'lettermove';

    prepare(wrapper = new AnimatedWordWC(), animIndex) {
        super.prepare(wrapper, animIndex);

    }


    placeWord(word) {

        this.getTarget().innerHTML = '';
        for(let i of word) {
            const letter = document.createElement('span');
            if(i === ' ') i = '&nbsp;'
            letter.innerHTML = i;
            this.getTarget().appendChild(letter);
        }
    }

    prepareShow() {
        this.showAnim = [
            { offset: 0, transform: 'scale3d(.3, .3, 1) translate3d(-50%,-50%,0)', opacity: 0},
            { offset: 0.7, transform: 'scale3d(2,2,1) translate3d(0,0,0)', opacity: 1},
            { offset: 1, transform: 'scale3d(1,1,1) translate3d(0,0,0)', opacity: 1}
        ];
    }

    prepareHide() {
        this.hideAnim = [
            { offset: 0, transform: 'scale3d(1,1,1) translate3d(0,0,0)', opacity: 1},
            { offset: 0.3, transform: 'scale3d(1.4,1.4,1) translate3d(0,0,0)', opacity: 1},
            { offset: 1, transform: 'scale3d(.6,.6,1) translate3d(0,0,0)', opacity: 0}
        ];
    }

    playInAnimation(defRes) {
        const delay = 100;
        let index = 0;
        let currentAnimation;
        this.getTarget().style.opacity = 1;
        for(let letter of this.getTarget().children) {
            const localDelay = delay * index++;
            currentAnimation = letter.animate(this.showAnim, {duration: this.wordInTime, delay:  + localDelay, fill: 'forwards'});
        }

        if(currentAnimation) currentAnimation.onfinish = () => defRes(true);

    }

    playOutAnimation(defRes) {
        const delay = 100;
        let index = this.getTarget().children.length - 1;
        let currentAnimation;
        let firstAnim;
        for(let letter of this.getTarget().children) {

            const localDelay = delay * index--;
            currentAnimation = letter.animate(this.hideAnim, {duration: this.wordInTime, delay:  + localDelay, fill: 'forwards'});
            if(!firstAnim) firstAnim = currentAnimation;
        }

        if(firstAnim) firstAnim.onfinish = () => {
            this.getTarget().style.opacity = 0;
            defRes(true);
        };
    }
}