import Lazy from "./lazy/Lazy.js";

class Navigation {

    constructor(el) {
        this.el = el;
        this.wrapper = el.querySelector('.nav-wrapper');
        this.control = el.querySelector('#nav-control');
        this.logo = this.wrapper.querySelector('#nav-logo');
        this.isOpen = false;
    }

    init() {
        this.control.addEventListener('click', () => this.toggleOpen())
        const anchorMap = new Map();

        this.el.querySelectorAll('web-anchor').forEach(anchor => {
            if(anchor.dataset.target) {
                anchorMap.set(anchor.dataset.target, anchor);
            }
            anchor.addEventListener('click', () => this.close())
        })

        const sections = []
        document.querySelectorAll('section').forEach(section => {
            if (section.id) {
                sections.push(section);
            }
        })

        if(sections.length > 0) {
            new Lazy({targets: sections, persist: true, loadFn: (el)=> {
                for(const anchor of anchorMap.values()) {anchor.classList.remove('active');}
                const mapKey = '#' + el.id;
                if(anchorMap.has(mapKey)) {
                    anchorMap.get(mapKey).classList.add('active');
                }
            }})
        }
    }

    toggleOpen() {
        if(this.isOpen) this.close();
        else this.open();
    }
    open() {
        if(!this.isOpen) {
            this.isOpen = true;
            this.el.classList.add('open');
        }
    }

    close() {
        if(this.isOpen) {
            this.isOpen = false;
            this.el.classList.remove('open');
        }
    }
}


export default function() {
    const navEl = document.querySelector('#nav');
    if(navEl) {
        new Navigation(navEl).init();
    }
}