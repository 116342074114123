const tools = {

    overlayHandler: null,
    overlayVisible: false,

    initOverlayHandler(overlay = new Overlay()) {
        if (overlay) {
            this.overlayHandler = overlay;
            document.addEventListener('overlayOpen', e => tools.overlayVisible = true);
            document.addEventListener('overlayClose', e => tools.overlayVisible = false);

            document.addEventListener('keyup', e => {
                if (e.key === 'Escape') {
                    this.overlayHandler.closeBtn.click();
                }
            })
        }
    },

    showInOverlay(options = new OverlayOptions()) {

        if (this.overlayHandler) {

            this.overlayHandler.display(options.getElement(), options.getOnDisplay(), options.getOverlayClass(), options.isEmptyOnClose())
            if (options.getOnClose()) {
                this.overlayHandler.setOnClose(options.getOnClose());
            }
        }
    },

    delayedPromise(delay = 0) {
        if(delay <= 0) {
            return Promise.resolve();
        } else {
            return new Promise((res, rej) => {
                const start = performance.now();
                function checkTime(timestamp) {
                    if(timestamp - start >= delay) {
                        res(true);
                    } else {
                        requestAnimationFrame(checkTime);
                    }
                }
                requestAnimationFrame(checkTime);
            })
        }
    },


    getCssVariable(el = new HTMLElement(), varName) {
        return window.getComputedStyle(el).getPropertyValue(varName);
    },

    getIntCssVariable(el = new HTMLElement(), varName) {
        const val = this.getCssVariable(el, varName);
        if(val) {
            return parseInt(val)
        }
        return null;
    },

    scrollToElt(parent, elt, margin = 0, duration = 700) {
        let destination = (parent === window ? (window.pageYOffset || window.scrollY) : elt.scrollTop);
        destination += elt.getBoundingClientRect().top + margin;
        return this.scrollTo(parent, destination, duration);
    },

    scrollTo(elt, destination, duration = 300) {
        let resolve;
        let reject;
        const promise = new Promise((res, rej) => {resolve = res; reject = rej});

        let start, distance, timeStart;

        function proceed() {
            start = elt === window ? (window.pageYOffset || window.scrollY) : elt.scrollTop;
            distance = destination - start;
            timeStart = null;
            requestAnimationFrame(loop);
        }

        function loop(time) {
            if (!timeStart) {
                timeStart = time;
            }

            let timeElapsed = time - timeStart;

            if (elt === window) {
                window.scrollTo(0, ease(timeElapsed, start, distance, duration))
            } else {
                elt.scrollTop = ease(timeElapsed, start, distance, duration);
            }

            if (timeElapsed < duration) {
                requestAnimationFrame(loop)
            } else {
                if (elt === window) {
                    window.scrollTo(0, destination)
                } else {
                    elt.scrollTop = destination;
                }
                timeStart = false;
                resolve(true);
            }
        }

        proceed();

        function ease(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b
        }

        return promise;
    },

    easeTo(start, target, action, duration = 300) {
        if (start && target && action) {
            let distance, timeStart;

            function proceed() {
                distance = target - start;
                timeStart = null;
                requestAnimationFrame(loop);
            }

            function loop(time) {
                if (!timeStart) {
                    timeStart = time;
                }
                let timeElapsed = time - timeStart;
                action(Math.round(ease(timeElapsed, start, distance, duration)));
                if (timeElapsed < duration) {
                    requestAnimationFrame(loop)
                } else {
                    action(target);
                    timeStart = false;
                }
            }

            proceed();

            function ease(t, b, c, d) {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b
            }
        }
    },



}

module.exports = tools;
