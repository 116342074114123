import { ScrollObserver, valueAtPercentage } from 'aatjs'

class CardStacker {
    constructor(el) {
        this.el = el;
        this.cardsContainer = null
        this.cards = []
        this.isResizing = false
        this.timeId = null
        this.lastWasOne = false
        this.scrollObservers = [];

    }

    init() {
        this.cardsContainer = document.querySelector('.card-stacker')
        if(this.cardsContainer) {
            this.cards = document.querySelectorAll('.card')
            this.cardsContainer.style.setProperty('--cards-count', this.cards.length)
            this.cardsContainer.style.setProperty(
                '--card-height',
                `350px`
            )
            Array.from(this.cards).forEach((card, index) => {
                const offsetTop = 100 + index * 22
                card.style.paddingTop = `${offsetTop}px`
                const toScale = 1 - (this.cards.length - 1 - index) * 0.06
                let nextCard = this.cards[index + 1]
                if (index === this.cards.length - 1) {
                    nextCard = this.cards[index]
                }
                const cardInner = card.querySelector('.service')
                const scrollObserver = ScrollObserver.Element(nextCard, {
                    offsetTop,
                    offsetBottom: window.innerHeight - 350
                });
                this.scrollObservers.push(scrollObserver);
                scrollObserver.onScroll(({ percentageY }) => {
                        cardInner.style.transform = `translateY(${valueAtPercentage({
                            from: 0,
                            to: -70,
                            percentage: percentageY,
                        })}px)`
                        cardInner.style.scale = valueAtPercentage({
                            from: 1,
                            to: toScale,
                            percentage: percentageY
                        })
                        cardInner.style.filter = `brightness(${valueAtPercentage({
                            from: 1,
                            to: 0.25 + (index * (0.75 / (this.cards.length - 1))),
                            percentage: percentageY
                        })})`
                })
            })
        }

    }
}

export default function init() {
    const cardStackerEl = document.getElementsByClassName('cards');
    let cardStacker = null
    if(cardStackerEl) {
        cardStacker = new CardStacker(cardStackerEl);
        cardStacker.init()
    }
    let timeId = null
    const resize = (e) => handleResize(e)
    window.addEventListener('resize', resize)
    function handleResize() {
        clearTimeout(timeId)
        timeId = setTimeout(() => {
            finishResize()
        })
    }

    function finishResize() {
/*        cardStacker.cards.forEach(card => {
            const cardInner = card.querySelector('.service')
            cardInner.style.transform = ''
            cardInner.style.scale = ''
            cardInner.style.filter = ''
        })*/
        cardStacker.scrollObservers.forEach(observer => observer.removeScrollListener());
        window.removeAllScrollListeners()
        cardStacker = new CardStacker(cardStackerEl);
        cardStacker.init()
    }
}
(function() {
    const originalAddEventListener = window.addEventListener;
    const originalRemoveEventListener = window.removeEventListener;

    // This will keep track of all listeners added to the window object
    window._eventListeners = { scroll: [] };

    window.addEventListener = function(type, listener, options) {
        if (type === 'scroll') {
            window._eventListeners.scroll.push({ listener, options });
        }
        originalAddEventListener.call(this, type, listener, options);
    };

    window.removeEventListener = function(type, listener, options) {
        if (type === 'scroll') {
            window._eventListeners.scroll = window._eventListeners.scroll.filter(
                registered => registered.listener !== listener || registered.options !== options
            );
        }
        originalRemoveEventListener.call(this, type, listener, options);
    };

    window.removeAllScrollListeners = function() {
        window._eventListeners.scroll.forEach(({ listener, options }) => {
            window.removeEventListener('scroll', listener, options);
        });
        window._eventListeners.scroll = []; // Clear the list
    };
})();