import AppearAnim from "./AppearAnim.js";
import AnimatedWordWC from "./AnimatedWordWC.js";


export default class ScaleToFront extends AppearAnim {
    name = 'scaletofront';

    prepare(wrapper = new AnimatedWordWC(), animIndex) {
        super.prepare(wrapper, animIndex);
    }

    prepareShow() {
        this.showAnim = [
            { offset: 0, transform: 'scale3d(.3,.3,1) translate3d(0,0,0)', opacity: 0},
            { offset: 0.6, transform: 'scale3d(1.7,1.7,1) translate3d(0,0,0)', opacity: 1},
            { offset: 1, transform: 'scale3d(1,1,1) translate3d(0,0,0)', opacity: 1}
        ];
    }

    prepareHide() {
        this.hideAnim = [
            { offset: 0, transform: 'scale3d(1,1,1) translate3d(0,0,0)', opacity: 1},
            { offset: 0.3, transform: 'scale3d(1.2,1.2,1) translate3d(0,0,0)', opacity: 1},
            { offset: 1, transform: 'scale3d(.3,.3,1)translate3d(0,0,0)', opacity: 0}
        ];
    }
}
