const tools = require('./tools.cjs');

export class OverlayOptions {
    #eltToDisplay;
    #onDisplayFn;
    #overlayClass;
    #emptyOnClose;
    #onCloseFn;

    constructor() {
        this.#eltToDisplay = null;
        this.#onDisplayFn = null;
        this.#overlayClass = null;
        this.#emptyOnClose = true;
        this.#onCloseFn = null;
        if (!tools.overlayHandler) tools.initOverlayHandler(new Overlay());

    }

    setElement(element = new HTMLElement()) {
        this.#eltToDisplay = element;
        return this;
    }

    getElement() {
        return this.#eltToDisplay;
    }

    onDisplay(onDisplayFn = (element) => {
    }) {
        this.#onDisplayFn = onDisplayFn;
        return this;
    }

    getOnDisplay() {
        return this.#onDisplayFn;
    }

    setOverlayClass(overlayClass = null) {
        this.#overlayClass = overlayClass;
        return this;
    }

    getOverlayClass() {
        return this.#overlayClass;
    }

    doEmptyOnClose(eoc = false) {
        this.#emptyOnClose = eoc;
        return this
    }

    isEmptyOnClose() {
        return this.#emptyOnClose;
    }

    onClose(onCloseFn = () => {
    }) {
        this.#onCloseFn = onCloseFn;
        return this;
    }

    getOnClose() {
        return this.#onCloseFn;
    }

    display() {
        tools.showInOverlay(this);
    }
}

class Overlay {
    constructor(overlayIdPrefix = 'shared') {
        this.id = overlayIdPrefix + '-overlay';
        this.el = document.querySelector('#' + this.id);
        this.wrapper = null;
        this.content = null;
        this.closeBtn = null;
        this.customClass = [];
        this.currentContentId = null;
        this.init();
        this.visible = this.el.classList.contains('visible');
    }

    init() {

        if (!this.el) {
            this.injectOverlayElement();
        }

        this.wrapper = this.el.querySelector('#' + this.id + '-wrapper');
        this.scrollable = this.el.querySelector('#' + this.id + '-scroll');
        this.content = this.el.querySelector('#' + this.id + '-content');
        this.closeBtn = this.el.querySelector('.overlay-close');

        if (this.closeBtn) {
            this.closeBtn.onclick = () => {
            };
            this.closeBtn.ontouchstart = () => {
            };
        }


        this.el.addEventListener('click', e => {
            if (e.target.id === this.id) {
                this.closeBtn.click();
            }
        })

        this.el.addEventListener('touchstart', e => {
            if (e.target.id === this.id) {
                this.closeBtn.click();
            }
        })
    }

    injectOverlayElement() {
        let overlayWrapper = document.createElement('div');
        overlayWrapper.id = this.id;
        overlayWrapper.classList.add('overlay')
        overlayWrapper.innerHTML =
            '<div id="' + this.id + '-wrapper" class="overlay-wrapper">' +
            '<button class="overlay-close close-btn"></button>' +
            '<div id="' + this.id + '-scroll" class="overlay-scroll">' +
            '<div id="' + this.id + '-content" class="overlay-content content-width"></div>' +
            '</div>' +
            '</div>';

        document.body.insertAdjacentElement('beforeend', overlayWrapper);

        this.el = overlayWrapper;
    }

    display(elem, fn, overlayClass, emtyOnClose = false) {
        if (this.visible) {
            this.hide();
        } else {
            document.dispatchEvent(new Event('overlayOpen'));
            document.body.style.overflow = 'hidden';
        }


        if (overlayClass) {
            this.removeCustomClass();
            this.setCustomClass(overlayClass);
        }

        if (!elem.id || this.currentContentId !== elem.id) {
            this.content.innerHTML = elem.outerHTML;
            this.el.querySelectorAll('.close-btn').forEach(btn => {
                btn.onclick = () => this.hide();
            })
            if (fn) {
                fn(this.content.firstElementChild);
            }
        }

        if (emtyOnClose) {
            this.content.classList.add('eoc');
        } else if (elem.id) {
            this.currentContentId = elem.id;
        }

        return this.show();
    }

    setOnClose(fn) {
        this.onClose = fn;
    }

    show() {

        if (!this.visible) {
            this.content.scrollTop = 0;
            this.visible = true;
            this.el.classList.add('visible');
            document.body.classList.add('fixed')
            return tools.delayedPromise(tools.getIntCssVariable(this.el, '--overlayAnimDuration'))
        }
        return Promise.resolve();
    }

    hide() {
        if (this.visible) {
            this.visible = false;
            this.el.classList.remove('visible');

            tools.delayedPromise(300).then(() => {
                //empty on close
                if (this.content.classList.contains('eoc')) {
                    if(!this.visible) {
                        this.content.innerHTML = '';
                        this.currentContentId = null;
                        this.content.classList.remove('eoc');
                    }
                }
                document.dispatchEvent(new Event('overlayClose'));
                document.body.classList.remove('fixed')

                if (this.onClose) {
                    this.onClose();
                    this.onClose = null;
                }
            })
            document.body.style.overflow = '';
            document.body.style.overflowX = 'hidden'

            return true;
        }
        return false;
    }

    isVisible() {
        return this.visible;
    }

    setCustomClass(classs) {
        if (classs) {
            this.customClass.push(classs);
            this.el.classList.add(classs);
        }
    }

    hasCustomClass(classs) {
        return this.el.classList.contains(classs);
    }

    removeCustomClass(className) {
        if (this.customClass) {
            if (className) {
                this.customClass = this.customClass.reduce(
                    (a, b) => {
                        if (b !== className) {
                            a.push(b);
                        }
                        return a;
                    }
                    , [])
                this.el.classList.remove(className);
            } else {
                this.customClass.forEach(c => this.el.classList.remove(c))
                this.customClass = [];
            }
        }
    }
}

export default Overlay;

