import Spinner from "./Spinner.js";
import ScaleToFront from "./ScaleToFront.js";
import Idle from "./Idle.js";
import LetterMove from "./LetterMove.js";
import Clip from "./Clip.js";
import Wiper from "./Wiper.js";

export default class AnimatedWordWC extends HTMLElement {
    #animations= [];
    #animsNb;
    #animIndex = 4;
    #started = false;
    #finished = false;
    #autoplay = false;
    constructor() {
        super();
        this.prepare();
    }

    reset() {
        const prom = this.prepareForNext();
        this.#animIndex = 4;
        this.#started = false;
        this.#finished = false;
        return prom;
    }

    prepare() {
        this.#autoplay = this.dataset.play === 'auto';
        this.#animsNb = parseInt(this.dataset.anims);
        for(let i = 1; i <= this.#animsNb; i++) {
            const animName = this.dataset['anim' + i];

            let anim;
            switch (animName) {
                case 'spinner':
                    anim = new Spinner();
                    break;
                case 'scaletofront':
                    anim = new ScaleToFront();
                    break;
                case 'idle':
                    anim = new Idle();
                    break;
                case 'lettermove':
                    anim = new LetterMove();
                    break;
                case 'clip':
                    anim = new Clip();
                    break;
                case 'wiper':
                    anim = new Wiper();
                    break;
                default:
                    anim = new Spinner();
            }

            anim.prepare(this, i);
            this.#animations.push(anim);
        }

        if(this.#autoplay) this.play();
    }


    play(delay = 0) {
        this.#started = true;


        let animPromise = () => {
            if(this.#animIndex < this.#animsNb) {
                return this.#animations[this.#animIndex++].animate()
            } else {
                return Promise.reject();
            }
        }

        if(delay > 0) {
            return new Promise((res, ref) => {
                setTimeout(delay, () => res(true))
            }).then(animPromise)
        } else return animPromise();
    }

    hasNext() {
        return this.#animIndex < this.#animsNb;
    }

    idling() {
        return this.#animations[this.#animIndex] instanceof Idle;
    }

    prepareForNext() {
        if(this.#started) {
            const prevIndex = this.#animIndex - 1;
            return this.#animations[prevIndex].prepareForNext();
        } else {
            this.#finished = true;
            return Promise.resolve();
        }
    }

    currentAnimName() {
        return this.#animations[this.#animIndex].name;
    }

}