const tools = require('../tools.cjs');
import Anim from './Anim.js';


export default class extends Anim {
    wordInTime
    wordOutTime
    wordIdling

    words;
    classes;
    prevClass;

    showAnim;
    hideAnim;

    prepare(wrapper, animIndex) {
        super.prepare(wrapper, animIndex);
        this.words = this.getConfigFor('words').split(',');
        this.classes = this.getConfigFor('classes').split(',');
        this.wordInTime = parseInt(this.getConfigFor('in') || 300);
        this.wordOutTime = parseInt(this.getConfigFor('out') || 200);
        this.wordIdling = parseInt(this.getConfigFor('idle') || 700);
        this.prepareShow();
        this.prepareHide();
    }

    prepareShow() {
        throw "Has to be implemented in subclass";
    }
    prepareHide() {
        throw "Has to be implemented in subclass";
    }

    placeWord(word) {
        this.getTarget().textContent = word;
    }

    show(word, klass) {
        let defRes, defRej;
        const showPromise = new Promise((res, rej) => {
            defRes = res;
            defRej = rej;
        })

        this.placeWord(word);

        //word classes
        if(this.prevClass) {this.getTarget().classList.remove(this.prevClass)}
        this.getTarget().classList.add(klass);
        this.prevClass = klass;

        this.setVisible();

        this.getTarget().getAnimations().forEach(anim => anim.cancel());
        this.playInAnimation(defRes);

        return showPromise;
    }

    playInAnimation(defRes)  {
        this.getTarget().animate(this.showAnim, {duration: this.wordInTime, fill: 'forwards'}).onfinish = () => defRes(true);
    }


    playOutAnimation(defRes)  {
        this.getTarget().animate(this.hideAnim, {duration: this.wordOutTime, fill: 'forwards'}).onfinish = () => defRes(true)
    }

    hide() {
        let defRes, defRej;
        const hidePromise = new Promise((res, rej) => {
            defRes = res;
            defRej = rej;
        })
        this.setHidden();
        this.playOutAnimation(defRes);
        return hidePromise;
    }

    animate() {
        this.cleanTargetClass();
        return this.words.reduce((animChain, word, index) => {
            return animChain.then(() => {
                const klass = this.classes.length > index ? this.classes[index] : 'white';
                return animChain
                    .then(() => this.isVisible() ? this.hide() : Promise.resolve())
                    .then(() => this.show(word.trim(), klass.trim()))
                    .then(() => tools.delayedPromise(this.wordIdling))
            })
        }, Promise.resolve())
    }

    clean() {
        return Promise.resolve();
    }

}

