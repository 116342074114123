import Splide from "@splidejs/splide";
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';


class Project {
    constructor(el) {
        this.el = el;
        if (this.el) {
            this.logoSlider = this.el.querySelector('.logo-slider');
            this.projectSlider = this.el.querySelector('.project-slider');
        }
        this.projects = el.querySelectorAll('.project-item');
        this.isMounted = false
        this.showAll = false
    }

    init() {
        this.addResizeEvent()
        const ls = new Splide(this.logoSlider, {
            type: 'loop',
            focus: 'center',
            pagination: false,
            arrows: false,
            gap: 80,
            autoWidth: true,
            pauseOnHover: false,
            autoplay: true,
            interval: 2000,
            speed: 3500,
            drag: false,

            autoScroll: {
                pauseOnHover: false
            }
        });
        ls.mount( {AutoScroll} );

        this.ps = new Splide(this.projectSlider, {
            type: 'slide',
            start: 0,
            focus: 'center',
            autoplay: false,
            pagination: false,
            arrows: true,
            perPage: 1,
            gap: 80,
            autoWidth: true,
            trimSpace: false,
            padding: { left: 60, right: 60 },
            breakpoints: {
                1024: {
                    perPage: 1,
                    autoWidth: false,
                },
                768: {
                    padding: { left: 20, right: 20 },
                }
            }
        });

        this.ps.mount();
        this.isMounted = true
        this.handleResizeEvent()

        let showAll = this.el.querySelector('#show-all-projects');
        showAll.addEventListener('click', (e) => {
            let scrollY = window.scrollY
            this.projectSlider.classList.add("show-all");
            if (this.isMounted) {
                this.ps.destroy();
                this.isMounted = false
            }
            for (let i = 0; i < this.projects.length; i++) {
                this.projects[i].style.display = 'block';
            }
            showAll.style.display = 'none';
            this.showAll = true;
            window.scrollTo(0, scrollY)
        })

    }

    addResizeEvent() {
        window.addEventListener('resize', e => {
            this.handleResizeEvent()
        })
    }

    handleResizeEvent() {
        if (!this.showAll) {
            if (window.innerWidth > 868) {
                this.projectSlider.classList.remove("show-all");
                if (this.isMounted === false) {
                    this.ps.mount();
                    this.isMounted = true
                }
                for (let i = 0; i < this.projects.length; i++) {
                    this.projects[i].style.display = 'block';
                }
            } else {
                this.projectSlider.classList.add("show-all");
                this.ps.destroy();
                this.isMounted = false
                for (let i = 0; i < this.projects.length; i++) {
                    if (i > 1) {
                        this.projects[i].style.display = 'none';
                    } else {
                        this.projects[i].style.display = 'block';
                    }
                }
            }
        }
    }
}

export default function init() {
    let projectEl = document.querySelector("#project");
    if(projectEl) {
        const project = new Project(projectEl);
        project.init();
    }
}