import Lazy from "../lazy/Lazy.js";
import ExtremeEnds from "./ExtremeEnds.js";
import ImageDivider from "./ImageDivider.js";

class DividerArranger {
    constructor(elt) {
        this.elt = elt;
        this.dividers = []
    }

    init()  {

        document.querySelectorAll(".image-divider").forEach(elt => {
            const divider = new ImageDivider(elt, this);
            this.dividers.push(divider);
            divider.init();
        });
        this.hideImgOnTopAndBottomOverscroll()
    }

    hideImgOnTopAndBottomOverscroll() {
        this.elt.style.visibility = 'hidden'
        if (window.scrollY > 100 && window.scrollY < document.documentElement.scrollHeight - window.innerHeight - 100) {
            this.elt.style.visibility = 'visible'
        }

        const scroll = (e) => this.handleScrollEvent(e)
        document.addEventListener('scroll', scroll)
    }

    handleScrollEvent(e) {
        if (window.scrollY > 100 && window.scrollY < document.documentElement.scrollHeight - window.innerHeight - 100) {
            this.elt.style.visibility = 'visible'
        } else {
            this.elt.style.visibility = 'hidden'
        }
    }

    addDivider(elt, divider) {
        elt.classList.add('divider');
        divider.setDividerElt(this.elt.insertAdjacentElement('beforeend', elt));
    }

    provideVisibility(imageDivider) {
        this.dividers.forEach(d => {
            if(!d.equals(imageDivider)) {
                d.hide();
            }
        })
        imageDivider.show();
    }
}

export default function init() {
    const dividersArrangerElt = document.querySelector('#dividers-arranger');
    let dividerArranger = null
    if(dividersArrangerElt) {
        dividerArranger = new DividerArranger(dividersArrangerElt)
        dividerArranger.init();
    }
    window.addEventListener('resize', handleResizeEvent)

    function handleResizeEvent() {
        for (let i = 0; i < dividerArranger.dividers.length; i++) {
            dividerArranger.dividers[i].recalculateHeight()
        }
    }
}