import AnimatedWordWC from "./AnimatedWordWC.js";
import AnimatedHeroWC from "./AnimatedHeroWC.js";

customElements.define('animated-hero', AnimatedHeroWC);
customElements.define('animated-word', AnimatedWordWC);

// export default function init() {
//     const animatedHero = document.querySelector('#word-spinner');
//     if(animatedHero) animatedHero.anim();
// }
