require('./heroanimation/HeroAnimation.js');
import AnchorWC from './AnchorWC.js';
import Overlay from './Overlay.js'
import Navigation from './Navigation.js'
import project from './Project.js';
import dividers from './divider/dividersArranger.js';
import CardStacker from "./CardStacker.js";
import team from './Team.js';
import {lazyHandler} from "./lazy/lazyHandler.js";

const tools = require('./tools.cjs');
customElements.define('web-anchor', AnchorWC);


function init() {
    //Here call your components init fn
    Navigation();
    lazyHandler();
    CardStacker();
    tools.initOverlayHandler(new Overlay());
    project();
    dividers();
    team();
    setTimeout(() => {
        import('./WaterMorph.js')
            .then(({ default: WaterMorph }) => {
                WaterMorph();
            })
            .catch(error => {
                console.error('Failed to load WaterMorph module:', error);
            });
    }, 500);
}

document.addEventListener('DOMContentLoaded', () => {
    init();
})