
class Anim {
    #wrapper
    #target;
    #config
    #animIndex;
    visible = false;
    name;

    prepare(wrapper, animIndex = 1) {
        this.#wrapper = wrapper;
        this.#config = wrapper.dataset;
        this.#animIndex = animIndex;

        if (wrapper.dataset.target) {
            this.#target = wrapper.querySelector(wrapper.dataset.target);
        }
    }


    getConfigFor(confName) {
        return this.#config[confName + this.#animIndex];
    }

    getConfig() {
        return this.#config;
    }

    getWrapper() {
        return this.#wrapper;
    }

    getTarget() {
        return this.#target;
    }

    show(word) {
        return Promise.reject('show() needs to be implemented in a sub class');
    }

    hide() {
        return Promise.reject('hide() needs to be implemented in a sub class');
    }

    setVisible() {
        this.visible = true;
    }

    setHidden() {
        this.visible = false;
    }

    isVisible() {
        return this.visible;
    }

    animate() {
        return Promise.reject('animate() needs to be implemented in a sub class');
    }

    cleanTargetClass() {
        this.getTarget().className = 'anim-word';
    }

    clean() {
        return Promise.reject('clean() needs to be implemented in a sub class');
    }

    prepareForNext() {
        return this.hide().then(() => this.clean());
    }
}


export default Anim;