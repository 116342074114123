
class Team {
    constructor(el) {
        this.el = el;
        this.teamgrid = this.el.querySelector(".teamgrid")
    }

    init() {
        this.shuffleDivs()
        this.teamgrid.classList.remove("hide")
    }

    shuffleDivs() {
        const allDivs = Array.from(this.teamgrid.children);
        const fixedDivs = [];
        const nonFixedDivs = [];

        // Separate fixed and non-fixed divs
        allDivs.forEach((div, index) => {
            if (div.classList.contains('fixed')) {
                fixedDivs.push({ div, index });
            } else {
                nonFixedDivs.push(div);
            }
        });

        // Fisher-Yates shuffle algorithm for non-fixed divs
        for (let i = nonFixedDivs.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [nonFixedDivs[i], nonFixedDivs[j]] = [nonFixedDivs[j], nonFixedDivs[i]];
        }

        // Merge fixed and shuffled non-fixed divs back together
        const shuffledDivs = [];
        let nonFixedIndex = 0;

        for (let i = 0; i < allDivs.length; i++) {
            const fixedDiv = fixedDivs.find(fixed => fixed.index === i);
            if (fixedDiv) {
                shuffledDivs.push(fixedDiv.div);
            } else {
                shuffledDivs.push(nonFixedDivs[nonFixedIndex]);
                nonFixedIndex++;
            }
        }

        // Append merged divs back to the container
        shuffledDivs.forEach(div => this.teamgrid.appendChild(div));

    }

    parallaxism() {
        const thoseones = [];
        this.members = this.teamgrid.querySelectorAll('.teammember').forEach(m => {
            m.img = m.querySelector('img');
            if(m.img) {
                m.img.style.opacity = 1;
                thoseones.push(m);
            }
        })

        doTheLoop(thoseones);
    }

}

const doTheLoop = (members) => {
    const wHeight = window.innerHeight;
    let modulo = Math.floor(window.innerWidth / (305));
    if(modulo > 3) modulo = 3;
    let row = 0;

    members.forEach((m, i) => {
        m.modulo = i  % modulo;
        const oddRow = row % 2 !== 0;

        const newRow = (i + 1) % modulo === 0 ? row + 1 : row;

        if(oddRow && newRow !== row) {
            const fromI = i - modulo + 1;

            for(let j = fromI, k = i; j < k; j++, k--) {
                const temp = members[j].modulo;
                console.log(`j: ${j}, k: ${k}`)
                console.log(`moduloJ: ${temp}, moduloK: ${members[k].modulo}`)
                members[j].modulo = members[k].modulo;
                members[k].modulo = temp;
            }
        }
        row = newRow;
    })

    const step = (ts) => {
        members.forEach((m, i) => {
            const y = m.getBoundingClientRect().top
            let imgY = 120 + (wHeight * 0.5 - (m.modulo * 100)) - y;
            if(imgY > 120) imgY = 120;
            if(imgY < 0) imgY = 0;
            m.img.style.transform = 'translate3d(0, -' + imgY + 'px,0)';
        })

        requestAnimationFrame(step);
    }

    requestAnimationFrame(step)
}

export default function init() {
    let teamEl = document.querySelector("#team");
    if(teamEl) {
        const team = new Team(teamEl);
        team.init();
    }
}