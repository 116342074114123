import AppearAnim from "./AppearAnim.js";
import AnimatedWordWC from "./AnimatedWordWC.js";

export default class Spinner extends AppearAnim {
    name = 'spinner';

    prepare(wrapper = new AnimatedWordWC(), animIndex) {
        super.prepare(wrapper, animIndex);
    }

    prepareShow() {
        this.showAnim = [
            { offset: 0, transform: 'translate3d(0,-100%,0)', opacity: 0},
            { offset: 0.7, transform: 'translate3d(0,10%,0)', opacity: 1},
            { offset: 1, transform: 'translate3d(0, 0, 0)', opacity: 1}
        ];
    }

    prepareHide() {
        this.hideAnim = [
            { offset: 0, transform: 'translate3d(0,0,0)', opacity: 1},
            { offset: 0.3, transform: 'translate3d(0,0,0)', opacity: 1},
            { offset: 1, transform: 'translate3d(0,100%,0)', opacity: 0}
        ];
    }
}
