import Lazy from './Lazy';

class LazyImg extends Lazy {

    constructor(selector='.lazy-anim') {
        super({
            targets: selector,
            loadFn: animatedWord => {
                if(animatedWord.playedOnce) animatedWord.play(1000);
                else {
                    animatedWord.play()
                    animatedWord.playedOnce = true;
                }
            },
            threshold: 0.5,
            rootMargin: {top: 0, right: 0, left: 0, bottom: 0},
            persist: true,
            simLoadLimit: 20,
            simLoadDelay: 100,
        })
    }
}

export default LazyImg;
