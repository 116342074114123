import AppearAnim from "./AppearAnim.js";
import AnimatedWordWC from "./AnimatedWordWC.js";

export default class Clip extends AppearAnim {
    name = 'clip';

    prepare(wrapper = new AnimatedWordWC(), animIndex) {
        super.prepare(wrapper, animIndex);
    }

    setStyles() {
        this.getTarget().style.background = 'linear-gradient(90deg, transparent 24.9%, #ffffff 35%, #ffffff 65%, transparent 74.9%)';
        this.getTarget().style.backgroundClip = 'text';
        this.getTarget().style.backgroundSize = '300% 200%';
        this.getTarget().style.color = 'transparent';
        this.getTarget().style.opacity = '1';
    }

    unsetStyles() {
        this.getTarget().style.background = null;
        this.getTarget().style.backgroundSize = null;
        this.getTarget().style.backgroundSize = null;
        this.getTarget().style.color = null;
        this.getTarget().style.opacity = '0';
    }

    prepareShow() {
        this.showAnim = [
            { offset: 0, backgroundPosition: '0% 50%'},
            { offset: 1, backgroundPosition: '50% 50%'},
        ];
    }

    playInAnimation(defRes) {
        this.setStyles();
        super.playInAnimation(defRes);
    }


    prepareHide() {
        this.hideAnim = [
            { offset: 0, backgroundPosition: '50% 50%'},
            { offset: 1, backgroundPosition: '100% 50%'}
        ];
    }

    clean() {
        this.unsetStyles();
        return super.clean();
    }
}
